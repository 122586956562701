







































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import TermsAndConditionsAllianz
  from '@/vue-app/components/contract-savings/before-start/TermsAndConditionsAllianz.vue';
import NoticeOfPrivacy
  from '@/vue-app/components/contract-savings/before-start/NoticeOfPrivacy.vue';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import ContractSavingsInvestmenInsuranceAllianzViewModel
  from '@/vue-app/view-models/components/contract-savings/before-start/contract-savings-investmen-insurance-allianz-view-model';

@Component({
  name: 'PrepareDocuments',
  components: { TermsAndConditionsAllianz, NoticeOfPrivacy },
})
export default class PrepareDocuments extends Vue {
  insurance_view_model = Vue.observable(
    new ContractSavingsInvestmenInsuranceAllianzViewModel(),
  );

  @Watch('insurance_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  async created() {
    this.insurance_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.insurance_view_model.initialize();
  }

  async nextStep() {
    const save_step = await this.insurance_view_model.saveStep();
    if (save_step) {
      this.$emit('nextStep');
    }
  }
}

